@import "styles/includes";

$popup-container-position: fixed !default;
$popup-container-top: 0 !default;
$popup-container-left: 0 !default;
$popup-container-height: 100% !default;
$popup-container-width: 100% !default;
$popup-container-z-index: 9999 !default;
$popup-container-overflow-y: scroll !default;
$popup-container-webkit-overflow-scrolling: touch !default;
$popup-container-font-size: 15px !default;

$popup-container-backdrop-position: fixed !default;
$popup-container-backdrop-top: 0 !default;
$popup-container-backdrop-left: 0 !default;
$popup-container-backdrop-min-height: 100% !default;
$popup-container-backdrop-width: 100% !default;
$popup-container-backdrop-cursor: alias !default;
$popup-container-backdrop-bg: rgba(33, 33, 33, 0.65) !default;

$popup-container-pointer-events: none !default;

$popup-position: relative !default;
$popup-pointer-events: auto !default;
$popup-border: 1px solid #d2d2d2 !default;
$popup-border-radius: $border-radius !default;
$popup-bg: $white !default;

$popup-overflow-wrap: break-word;

$popup-list-padding-left: 20px !default;

$popup-fa-position: absolute !default;
$popup-fa-top: 0 !default;
$popup-fa-right: 0 !default;
$popup-fa-color: unset !default;
$popup-fa-padding: 15px !default;
$popup-fa-cursor: pointer !default;

.popup-container {
  position: $popup-container-position;
  top: $popup-container-top;
  left: $popup-container-left;
  height: $popup-container-height;
  width: $popup-container-width;
  z-index: $popup-container-z-index;
  overflow-y: $popup-container-overflow-y;
  -webkit-overflow-scrolling: $popup-container-webkit-overflow-scrolling;
  font-size: $popup-container-font-size;

  @-moz-document url-prefix() {
    background: $popup-container-backdrop-bg;
  }

  .backdrop {
    position: $popup-container-backdrop-position;
    top: $popup-container-backdrop-top;
    left: $popup-container-backdrop-left;
    min-height: $popup-container-backdrop-min-height;
    width: $popup-container-backdrop-width;
    cursor: $popup-container-backdrop-cursor;
    background: $popup-container-backdrop-bg;
  }
  @-moz-document url-prefix() {
    .backdrop {
      display: none;
    }
  }

  .container {
    pointer-events: $popup-container-pointer-events;
  }
  .popup {
    position: $popup-position;
    pointer-events: $popup-pointer-events;
    border: $popup-border;
    border-radius: $popup-border-radius;
    background: $popup-bg;
    overflow-wrap: $popup-overflow-wrap;

    ul,
    ol {
      padding-left: $popup-list-padding-left;
    }

    .fa {
      position: $popup-fa-position;
      top: $popup-fa-top;
      right: $popup-fa-right;
      color: $popup-fa-color;
      padding: $popup-fa-padding;
      cursor: $popup-fa-cursor;
    }
  }
}
