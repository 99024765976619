
           @import "@/styles/includes";
        

p {
  font-size: 18px;
}

.aanvulblok {
  border: 1px solid #fcf3a3;
  background-color: #fffdee;

  p {
    font-size: 15px;
  }
}
