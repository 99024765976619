$primary: #ea7152;
$secondary: #1792f3;
$success: #52d00e;
$info: #ffede7;
$warning: #000;
$danger: #fe8f00;
$light: #fff;
$dark: #646464;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "warning": $warning,
  "danger": $danger,
  "success": $success,
  "dark": $dark,
  "light": $light,
  "info": $info,
);

$enable-responsive-font-sizes: true;

$headings-font-weight: 700;

$line-height-base: 1.7;

$body-color: #000;
$body-bg: #fff;

$btn-padding-y-lg: 1rem;
$btn-font-weight: 700;

// form styling
//$input-height: 68px;
$label-margin-bottom: 2px;
$form-group-margin-bottom: 9px;
$input-placeholder-color: #e0e0e0;
$input-border-color: #e0e0e0;
$input-color: #092547;

$input-padding-y: 25px;
$input-padding-x: 15px;

// form custom-select copy input styling
$custom-select-height: $input-height;
$custom-select-border-radius: $input-border-radius;
$custom-select-border-color: $input-border-color;
