html,
body {
  height: 100%;
}

#app {
  min-height: 100%;
}

* {
  font-family: "Gilroy";
}

.bg-cover {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.row img {
  max-width: 100%;
}

.bg-1 {
  background-image: url("@/../assets/bg-2.jpg");
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-2 {
  background-image: url("@/../assets/bg-1.jpg");
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-3 {
  background-image: url("@/../assets/bg-3.jpg");
  background-repeat: no-repeat;
  background-position: center center;
}

.bg-pattern {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4b58a3+0,303160+100&0.3+0,1+100 */
  background: -moz-linear-gradient(45deg, rgba(23, 146, 224, 0.6) 0%, rgba(48, 49, 96, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    45deg,
    rgba(23, 146, 224, 0.6) 0%,
    rgba(48, 49, 96, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    45deg,
    rgba(23, 146, 224, 0.4) 0%,
    rgba(48, 49, 96, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d4b58a3', endColorstr='#303160',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.cursor-pointer {
  cursor: pointer;
}

.zoom {
  transition: transform 0.2s;
}
.zoom:hover {
  transform: scale(1.1);
}

.profielfoto {
  border-radius: 50%;
  width: 100%;
  max-width: 125px !important;
}
