@font-face {
		font-family: 'Gilroy';
		font-style: normal;
		font-weight: 400;
		font-display: swap;
		src: url("./assets/fonts/gilroy-regular-webfont-1f780e740bb04a6b6db59ead83ebf5fe.woff2") format('woff2'),
			url("./assets/fonts/gilroy-regular-webfont-d0833272025b61d0b5b3e8a9da4017b5.woff") format('woff');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gilroy';
		font-style: normal;
		font-weight: 500;
		font-display: swap;
		src: url("./assets/fonts/gilroy-semibold-webfont-afa134c4b170119e4dfc0f2afcd96a6d.woff2") format('woff2'),
		url("./assets/fonts/gilroy-semibold-webfont-5d717f1fe2e78013e7b45512d0bd14cc.woff") format('woff');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}

	@font-face {
		font-family: 'Gilroy';
		font-style: normal;
		font-weight: 900;
		font-display: swap;
		src: url("./assets/fonts/gilroy-extrabold-webfont-8aa7244c32d321dbd84890e3d223a80f.woff2") format('woff2'),
		url("./assets/fonts/gilroy-extrabold-webfont-30f6dc851d0e2ba8d8d1286aedfdffff.woff") format('woff');
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
	}