
           @import "@/styles/includes";
        

@import "../styles/includes";

label {
  font-size: 13px;
}

h3 {
  font-size: 2rem;
  line-height: 1;

  @include media-breakpoint-down(md) {
    font-size: 1.3rem;
  }
}
