
           @import "@/styles/includes";
        

label {
  font-size: 13px;
}

h2 {
  font-size: 1.3rem;
}

.bedankt {
  background-color: #eeffee;
  border: 1px solid #9eec9e;
}
